import { ValidatorFn, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { IntegrationAppTitle } from '../../../reducers/orm/integration-apps/integration-app.model';
import {
  IntegrationContractField,
  IntegrationEmployeeField,
} from '../../../reducers/orm/integration/integration-configure.model';

export enum ConfigureAction {
  AUTHENTICATE,
  CONFIGURE,
}

export enum ConfigureMode {
  ADD,
  EDIT,
}

export const integrationAppId = {
  [IntegrationAppTitle.TWELVE]: 26,
  [IntegrationAppTitle.PERSONIO]: 38,
  [IntegrationAppTitle.PILOGA]: 39,
  [IntegrationAppTitle.BCS]: 40,
  [IntegrationAppTitle.HOORAY]: 41,
  [IntegrationAppTitle.TOP_CHEFS]: 43,
  [IntegrationAppTitle.BUDDEE]: 44,
  [IntegrationAppTitle.BORK]: 46,
  [IntegrationAppTitle.JIXBEE]: 47,
  [IntegrationAppTitle.NEXTUP]: 48,
};

export interface ConfigForm {
  name: string;
  label: string;
  validators?: ValidatorFn[];
  readonly?: boolean;
  separator?: boolean;
  isPassword?: boolean;
}

export interface ConfigureConfig {
  appId: number;
  authForm?: ConfigForm[];
  configureForm?: ConfigForm[];
  mappingUrl?: string;
  autoAddEmployees?: boolean;
  notifyImport?: boolean;
  notifyExport?: boolean;
  turnOverImportEmail?: boolean;
  employeeFields?: IntegrationEmployeeField[];
  contractFields?: IntegrationContractField[];
  authKeysInConfigure?: string[];
  importVat?: boolean;
}

export const appConfigureConfig: Record<string, ConfigureConfig> = {
  [IntegrationAppTitle.PERSONIO]: {
    appId: integrationAppId[IntegrationAppTitle.PERSONIO],
    authForm: [
      {
        name: 'client_id',
        label: _('Client id'),
        validators: [Validators.required],
      },
      {
        name: 'client_secret',
        label: _('Client secret'),
        validators: [Validators.required],
      },
    ],
    autoAddEmployees: true,
    notifyImport: true,
    notifyExport: true,
    employeeFields: [IntegrationEmployeeField.BIRTH_DATE],
    contractFields: [
      IntegrationContractField.FUNCTION,
      IntegrationContractField.END_DATE,
      IntegrationContractField.HOURS,
      IntegrationContractField.WAGE,
    ],
    mappingUrl: 'contract-department',
  },
  [IntegrationAppTitle.BCS]: {
    appId: integrationAppId[IntegrationAppTitle.BCS],
    authForm: [
      {
        name: 'clientId',
        label: _('Client id'),
        validators: [Validators.required],
      },
      {
        name: 'username',
        label: _('Username'),
        validators: [Validators.required],
      },
      {
        name: 'password',
        label: _('Password'),
        validators: [Validators.required],
        isPassword: true,
      },
    ],
    configureForm: [
      {
        name: 'fulltime_hours',
        label: _('Fulltime weekly hours'),
        validators: [Validators.required],
      },
    ],
    autoAddEmployees: true,
    notifyImport: true,
    notifyExport: true,
    employeeFields: [
      IntegrationEmployeeField.ADDRESS,
      IntegrationEmployeeField.BIRTH_DATE,
      IntegrationEmployeeField.BIRTH_PLACE,
      IntegrationEmployeeField.EMPLOYEE_NR,
      IntegrationEmployeeField.PHONE_NUMBERS,
      IntegrationEmployeeField.SSN,
    ],
    contractFields: [
      IntegrationContractField.FUNCTION,
      IntegrationContractField.END_DATE,
      IntegrationContractField.HOURS,
      IntegrationContractField.WAGE,
    ],
    mappingUrl: 'contract-department',
  },
  [IntegrationAppTitle.PILOGA]: {
    appId: integrationAppId[IntegrationAppTitle.PILOGA],
    configureForm: [
      {
        name: 'clientId',
        label: _('Client id'),
        validators: [Validators.required],
      },
    ],
    notifyExport: true,
    mappingUrl: 'cost-center',
  },
  [IntegrationAppTitle.TWELVE]: {
    appId: integrationAppId[IntegrationAppTitle.TWELVE],
    authForm: [
      {
        name: 'public_key',
        label: _('Public key'),
        validators: [Validators.required],
      },
      {
        name: 'private_key',
        label: _('Private key'),
        validators: [Validators.required],
      },
    ],
    authKeysInConfigure: ['public_key', 'private_key'],
    turnOverImportEmail: true,
    importVat: true,
    mappingUrl: 'general',
  },
  [IntegrationAppTitle.TOP_CHEFS]: {
    appId: integrationAppId[IntegrationAppTitle.TOP_CHEFS],
  },
  [IntegrationAppTitle.BUDDEE]: {
    appId: integrationAppId[IntegrationAppTitle.BUDDEE],
  },
  [IntegrationAppTitle.BORK]: {
    appId: integrationAppId[IntegrationAppTitle.BORK],
  },
  [IntegrationAppTitle.JIXBEE]: {
    appId: integrationAppId[IntegrationAppTitle.JIXBEE],
  },
  [IntegrationAppTitle.NEXTUP]: {
    appId: integrationAppId[IntegrationAppTitle.NEXTUP],
  },
};
